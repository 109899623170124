export const gallery = () => {
  var swiper = new Swiper(".mySwipering", {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-buttons-next",
      prevEl: ".swiper-buttons-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
    },
  });
};
