import { burger } from "./modules/header/burger.js";
import { modals } from "./modules/modals.js";
import { text } from "./modules/page/home/text.js";
import { baneru } from "./modules/page/home/baneru.js";
import { gallery } from "./modules/page/home/gallery.js";
import { sub } from "./modules/header/sub.js";
import { popup } from "./modules/popup.js";
document.addEventListener("DOMContentLoaded", function (event) {
  burger();
  modals();
  text();
  baneru();
  gallery();
  sub();
  popup();
});
