export const baneru = () => {
  var swiper = new Swiper(".myswiperf", {
    slidesPerView: 1,
    spaceBetween: 50,
    navigation: {
      nextEl: ".swiper-buttons-next",
      prevEl: ".swiper-buttons-prev",
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  });
};
